.header {
    height: 110px;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid white;
    @media(max-width: 520px){
        height: 72px;
    }
    .wrapTitle {
        padding: 5px;
        padding-left: 18px;
        padding-right: 18px;
        border: 3px solid rgba(241,133,0, 0.9);
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media(max-width: 520px){
            margin-left: 10px;
            padding-left: 10px;
            padding-right: 10px;
        }
        > span {
            font-size: 42px;
            color: rgba(241,133,0, 0.9);
            font-weight: 800;
            line-height: 40px;
            @media(max-width: 520px){
                font-size: 20px;
                font-weight: 800;
                line-height: 22px;
            }
        }
        .title {
            font-size: 28px;
            color: yellowgreen;
            font-weight: 800;
            line-height: 28px;
            @media(max-width: 520px){
                font-size: 18px;
                font-weight: 700;
                line-height: 20px;
            }
        }
    }
    
}