.wrapRedact {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .wrapMainPageLink {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 60px;
        @media(max-width: 520px){
            margin-right: 30px;
        }
        .homePic {
            font-size: 24px;
            color: #474A51;
            margin-right: 10px;
            margin-bottom: 3px;
            @media(max-width: 520px){
                font-size: 30px;
            }
        }
        .link {
            text-decoration: none;
            font-size: 22px;
            font-weight: 700;
            color: #474A51;
            @media(max-width: 520px){
                font-size: 18px;
            }
        }
    }
    .wrapDishes {
        padding: 12px;
        flex-direction: column;
        justify-content: center;
        width: 60%;
        margin-top: 14px;
        margin-bottom: 20px;
        border-radius: 20px;
        box-shadow: 0 2px 6px 4px rgba(0,0,0,0.5);
        @media(max-width: 520px){
            width: 92%;
        }
        .title {
            font-size: 24px;
            font-weight: 700;
            color: rgba(241,133,0, 0.9);
            margin-bottom: 18px;
            margin-top: 5px;
            font-family: 'Montserrat', sans-serif;
            @media(max-width: 520px){
                font-size: 22px;
                margin-bottom: 12px;
            }
        }
        .discriptions {
            width: 100%;
            height: 120px;
            border: 3px solid gray;
            border-radius: 12px;
            //padding-left: 10px;
            font-size: 22px;
            color: gray;
            font-weight: 700;
            font-family: 'Montserrat', sans-serif;
            @media(max-width: 520px){
                font-size: 20px;
            }
        }
        .wrapInput{
            border: none;
            outline: none;
            width: 100%;
            font-weight: 700;
            border-bottom: 3px solid gray;
            height: 40px;
            font-size: 22px;
            color: gray;
            font-family: 'Montserrat', sans-serif;
            @media(max-width: 520px){
                font-size: 20px;
            }
        }
        .wrapBtn {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .btn {
                width: 100%;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(241,133,0, 0.9);
                border-radius: 12px;
                box-shadow: 0 2px 3px 2px rgba(0,0,0,0.5);
                text-align: center;
                font-size: 22px;
                font-weight: 500;
                color: white;
                @media(max-width: 520px){
                    margin-top: 12px;
                    height: 52px;
                }
                &:active {
                    transform: translateY(4px);
                }
            }
        }
    }
    
}