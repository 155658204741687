@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  padding: 0px;
  margin: 0px;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;


  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  html {
    scroll-behavior: smooth;
  }
  ul[class],
  ol[class] {
    padding: 0;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

}

body::-webkit-scrollbar {
	width: 3px;
} 
body::-webkit-scrollbar-track {
	//background: rgb(159, 187, 218);
}
body::-webkit-scrollbar-thumb {
	border: 3px solid #eee;
	border-radius: 15px;
	background-color: gray;
}

