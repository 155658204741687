@import '../../styles/index.scss';

.wrapDishes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    .wrapMainPageLink {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 60px;
        margin-top: 5px;
        @media(max-width: 520px){
            margin-top: 2px;
            margin-right: 30px;
        }
        .homePic {
            font-size: 24px;
            color: #474A51;
            margin-right: 10px;
            margin-bottom: 3px;
            @media(max-width: 520px){
                font-size: 20px;
            }
        }
        .link {
            text-decoration: none;
            border-bottom: 3px solid #474A51;
            font-size: 22px;
            font-weight: 700;
            color: #474A51;
            @media(max-width: 520px){
                font-size: 18px;
                border-bottom: 2px solid #474A51;
            }
        }
    }
    .mainTitle {
        font-size: 24px;
        font-weight: 700;
        color: rgba(241,133,0, 0.9);
        margin-top: 14px;
        margin-bottom: 5px;
    }
    .pageLink {
        height: 34px;
        @media(max-width: 520px){
            height: 27px;
        }
    }
    .blockDishes {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        cursor: pointer;
        @media(max-width: 520px){
            width: 98%;
        }
        .dishes {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            @media(max-width: 520px){
                margin: 10px;
                flex-direction: column;
                justify-content: center;
                width: 90%;
                //border: 4px solid rgb(208,25,9);
                border-radius: 20px;
                box-shadow: 0 2px 6px 4px rgba(0,0,0,0.5);
            }
            .picDishes {
                width: 20%;
                height: auto;
                margin-left: 30px;
                @media(max-width: 520px){
                    margin-left: 0px;
                    width: 88%;
                    height: auto;
                }
            }
            .wrapDiscriptions {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 35%;
                margin-bottom: 20px;
                padding-left: 15px;
                padding-right: 30px;
                @media(max-width: 520px){
                    min-width: 90%;
                }
                .title {
                    font-size: 24px;
                    font-weight: 700;
                    color: rgba(241,133,0, 0.9);
                    margin-bottom: 18px;
                    margin-top: 5px;
                    font-family: 'Montserrat', sans-serif;
                    @media(max-width: 520px){
                        font-size: 22px;
                        margin-bottom: 12px;
                    }
                }
                .discriptions {
                    font-size: 22px;
                    font-weight: 600;
                    text-align: center;
                    color:  #474A51;
                    margin-top: 10px;
                    @media(max-width: 520px){
                        font-size: 15px;
                        margin-top: 0px;
                    }
                }
            }
            .wrapBuy {
                width: 15%;
                display: flex;
                justify-content: center;
                align-items: center;
                @media(max-width: 520px){
                    width: 100%;
                    margin-bottom: 10px;
                }
                .cost {
                    font-size: 20px;
                    font-weight: 600;
                    color:  #474A51;
                    margin-right: 18px;
                    @media(max-width: 520px){
                        font-size: 15px;
                        margin-right: 4px;
                    }
                }
            }
        }
    }
    .wrapBtn {
        width: 14%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
        @media(max-width: 520px){
            width: 100%;
        }
        .btn {
            width: 40%;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(241,133,0, 0.9);
            border-radius: 12px;
            box-shadow: 0 2px 3px 2px rgba(0,0,0,0.5);
            text-align: center;
            font-size: 22px;
            font-weight: 500;
            color: white;
            @media(max-width: 520px){
                margin-top: 12px;
                width: 18%;
                height: 52px;
            }
            &:active {
                transform: translateY(4px);
            }
        }
    }
    
}