@import '../../styles/index.scss';

.mainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; 
    align-items: center;
    .mainTitle {
        font-size: 24px;
        font-weight: 700;
        color: rgba(241,133,0, 0.9);
        margin-top: 14px;
        margin-bottom: 5px;
    }
    .wrapMenu {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 20px;
        cursor: pointer;
        @media(max-width: 520px){
            width: 98%;
        }
        .wrapDish {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 33%;
            @media(max-width: 520px){
                width: 49%;
            }
            .menu {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                border-radius: 14px;
                margin: 20px;
                margin-bottom: 5px;
                box-shadow: 0 0 5px 2px rgba(0,0,0,0.5);
                &:active {
                    box-shadow: 0 5px gray;
                    transform: translateY(4px);
                }
                width: 93%;
                height: auto;
                @media(max-width: 520px){
                    width: 93%;
                    height: auto;
                    border-radius: 6px;
                    margin: 10px;
                    margin-bottom: 3px;
                }
            }
            .wrapNameDish {
                width: 192px;
                height: 48px;
                background-color: white;
                border-radius: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                margin-bottom: -20%;
                text-align: center;
                @media(max-width: 520px){
                    width: 128px;
                    height: 38px;
                    border-radius: 6px;
                    margin-top: 94px;
                    margin-bottom: 0%;
                }
                .nameDish {
                    color: rgba(241,133,0, 0.9);
                    font-size: 22px;
                    font-weight: 600;
                    @media(max-width: 520px){
                        font-size: 15px;
                        flex-direction: row;
                        flex-wrap: wrap;
                    }
                }
            }
            .wrapEditing {
                width: 80px;
                height: 48px;
                background-color: white;
                border-radius: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                margin-bottom: -12%;
                text-align: center;
                @media(max-width: 520px){
                    width: 60px;
                    height: 38px;
                    border-radius: 6px;
                    margin-top: 94px;
                    margin-bottom: 22%;
                }
                .nameEditing {
                    color: rgba(241,133,0, 0.9);
                    font-size: 34px;
                    @media(max-width: 520px){
                        font-size: 20px;
                        flex-direction: row;
                        flex-wrap: wrap;
                    }
                }
            }
            
        }
    }
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 290px;
    height: 100px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 5px 2px rgba(0,0,0,0.5);
    cursor: pointer;
    > span {
        font-weight: 800;
        font-size: 19px;
        color: rgba(241,133,0, 0.9);
    }
}